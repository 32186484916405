import React from "react";
import "./Footer.css";
import twitterIcon from '../../Assets/images/twitter.png';
import instaIcon from '../../Assets/images/insta.svg';
import telegramIcon from '../../Assets/images/telegram.png';
import fbIcon from '../../Assets/images/facebook.png';
import mediumIcon from '../../Assets/images/medium.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_inner_row">
          <a href="/" aria-current="page" className="footer_logo_wrapper"></a>
        </div>

        <div className="footer_seperator">
          <div className="footer-seperator-line"></div>
        </div>

        <div className="footer_copyright_wrapper">
          <div className="footer_copyright_text">© 2024 Techmont. All rights reserved</div>
          <div className="community_icon_wrapper">
            <div className="icon-holder">
              <a href="https://x.com/Tech_Mont" target="_blank" className="icon_wrapper">
                <img className="ftr-icon" src={twitterIcon} alt="Twitter" />
              </a>
            </div>
            <div className="icon-holder">
              <a href="https://medium.com/@Tech_Mont" target="_blank" className="icon_wrapper">
                <img className="ftr-icon" src={mediumIcon} alt="Medium" />
              </a>
            </div>
            <div className="icon-holder">
              <a href="https://www.facebook.com/TechMont" target="_blank" className="icon_wrapper">
                <img className="ftr-icon" src={fbIcon} alt="Facebook" />
              </a>
            </div>
            <div className="icon-holder">
              <a href=" https://www.instagram.com/techmont9" target="_blank" className="icon_wrapper">
                <img className="ftr-icon" src={instaIcon} alt="Instagram" />
              </a>
            </div>
            <div className="icon-holder">
              <a href="https://t.me/TechMontGroup" target="_blank" className="icon_wrapper">
                <img className="ftr-icon" src={telegramIcon} alt="Telegram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
