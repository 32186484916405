import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { polygon, polygonMumbai } from 'wagmi/chains'
import { RainbowKitProvider, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';
import { publicProvider } from "wagmi/providers/public"
import '@rainbow-me/rainbowkit/styles.css';
import { Avatar } from '@mui/material';
const test = () => <Avatar sx={{ bgcolor: '#3df9ff', color: "#000" }} />

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon],
  [publicProvider(), publicProvider()]
);
const { connectors } = getDefaultWallets({
  appName: 'Defi Stake',
  projectId: '74ff0c8063613ea7db447832e6049d4d',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,

});






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider coolMode chains={chains} theme={darkTheme()} avatar={test} >
      <App />
    </RainbowKitProvider>
  </WagmiConfig>
);


reportWebVitals();
