import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, MenuItem, Menu, Button } from "@mui/material";
import DayTable from "../Admreport/Daytable";
import WeekTable from "../Admreport/Weektable";
import MonthTable from "../Admreport/Monthtable";
import ReportAnalytics from "../Admreport/Analytics";
import Payout from "../Admreport/Payout";
import Expirytable from "./Expirytable";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { display } from "@mui/system";
import { AdminReport2TableData } from '../../hooks/adminReport2Graph'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);


const AdmReport2 = () => {
  const [mainTab, setMainTab] = useState(0);
  const [subTab, setSubTab] = useState(null);
  const [anchorElSelect1, setAnchorElSelect1] = useState(null);
  const [anchorElSelect2, setAnchorElSelect2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [majorTokenPool, setMajorTokenPool] = useState("TMT");
  const [status, setStatus] = useState(null);
  const [pool, setPool] = useState(null);
  const [TableData, setTableData] = useState([]);

  const [timeRange, setTimeRange] = useState({ startTime: 0, endTime: 0 });
  const [btnTimeRange, setBtnTimeRange] = useState({
    startTime: null,
    endTime: null,
  });



  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };

  const handleStatusSelect = (statusStr) => {
    setStatus(statusStr);
    setAnchorElSelect1(null);

  }

  const handlePoolSelect = (poolStr) => {
    setPool(poolStr);
    setAnchorElSelect2(null);
  }

  const handleMenuItemClickSelect1 = (value) => {
    setSubTab(value);
    setAnchorElSelect1(null);
  };

  const handleMenuClickSelect2 = (event) => {
    setAnchorElSelect2(event.currentTarget);
  };

  const handleMenuItemClickSelect2 = (value) => {
    setSubTab(value);
    setAnchorElSelect2(null);
  };

  const handleCloseMenu = () => {
    setAnchorElSelect1(null);
    setAnchorElSelect2(null);
  };

  const dateToEpochConverter = (dateString) => {
    const dateObject = new Date(dateString);
    const epochTime = dateObject.getTime() / 1000;
    return epochTime;
  };

  const handleClear = () => {
    setTimeRange({ startTime: 0, endTime: 0 });
    setBtnTimeRange({ startTime: null, endTime: null });
    setSubTab(null);
  };


  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await AdminReport2TableData(majorTokenPool);
        setTableData(response);
      } catch (error) {
        console.error("Failed to fetch table data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTableData();
  }, [majorTokenPool]);


  const handleBtnRange = (btnRangeStr) => {

    if (btnRangeStr === "day") {

      const currentTimeInEpoch = dateToEpochConverter(new Date());
      const dayBefore = currentTimeInEpoch - 86400;
      setTimeRange({ startTime: dayBefore, endTime: currentTimeInEpoch });
      setBtnTimeRange({
        startTime: dayjs().utc().subtract(1, "day"),
        endTime: dayjs().utc(),
      });

    } else if (btnRangeStr === "week") {
      const currentTimeInEpoch = dateToEpochConverter(new Date());
      const weekBefore = currentTimeInEpoch - 604800;
      setTimeRange({ startTime: weekBefore, endTime: currentTimeInEpoch });
      setBtnTimeRange({
        startTime: dayjs().utc().subtract(7, "day"),
        endTime: dayjs().utc(),
      });
    } else {
      const currentTimeInEpoch = dateToEpochConverter(new Date());
      const monthBefore = currentTimeInEpoch - 2592000;
      setTimeRange({ startTime: monthBefore, endTime: currentTimeInEpoch });
      setBtnTimeRange({
        startTime: dayjs().utc().subtract(30, "day"),
        endTime: dayjs().utc(),
      });
    }
  };

  return (
    <>
      <ReportAnalytics />
      <Payout />

      <section
        className="boxsmain adminReportTable"
        style={{ marginTop: 40, marginBottom: 100 }}
      >
        <div className="os_container">
          <div className="titleh2 mt-5 ">
            <h2>Stake History </h2>
          </div>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#000",
              marginBottom: "50px",
              marginTop: "30px",
            }}
          >
            <div className="flex justify-content-center mb-5">

              <Tabs
                value={mainTab ?? 0}
                onChange={handleMainTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
              >
                <Tab
                  onClick={() => setMajorTokenPool("TMT")}
                  sx={{ color: "#fff" }}
                  label="TMT"
                />
                <Tab
                  onClick={() => setMajorTokenPool("USDT")}
                  sx={{ color: "#fff" }}
                  label="USDT"
                />
              </Tabs>
            </div>

          </Box>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   alignItems: "center",
              //   marginVertical: "auto",
              //   gap: 10,
              //   background: "green",
              // }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
                padding: "8px 0", // Added padding for vertical spacing, adjust as needed
              }}
              className="abcde"
            >
              <CalendarMonthIcon />
              <DateRangePicker
                value={[
                  btnTimeRange?.startTime ? btnTimeRange.startTime : null,
                  btnTimeRange?.endTime ? btnTimeRange.endTime : null,
                ]}
                slots={{ field: SingleInputDateRangeField }}
                timezone="UTC"
                name="allowedRange"
                onChange={(dateRange) => {
                  if (dateRange) {
                    if (dateRange[0] && dateRange[1]) {
                      setTimeRange({
                        startTime: dateToEpochConverter(dateRange[0]),
                        endTime: dateToEpochConverter(dateRange[1]),
                      });
                      setBtnTimeRange({
                        startTime: dateRange[0],
                        endTime: dateRange[1],
                      });
                    }
                  }
                }}
              />
              <Button
                onClick={() => handleClear()}
                className="btnexcel"
                variant="contained"
                color="primary"
                sx={{ height: "fit-content", alignSelf: "center", margin: 'auto' }} // Adjusting button alignment
              >
                Clear
              </Button>
            </div>
          </LocalizationProvider>

          <Box
            sx={{
              width: "100%",
              bgcolor: "#000",
              marginBottom: "50px",
              marginTop: "30px",
            }}
          >
            <div className="flex justify-content-center mb-5">

              <Tabs
                value={subTab ?? 0}
                onChange={handleSubTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
              >
                <Tab
                  onClick={() => handleBtnRange("day")}
                  sx={{ color: "#fff" }}
                  label="Days"
                />
                <Tab
                  onClick={() => handleBtnRange("week")}
                  sx={{ color: "#fff" }}
                  label="Week"
                />
                <Tab
                  onClick={() => handleBtnRange("month")}
                  sx={{ color: "#fff" }}
                  label="Month"
                />
                <Button
                  variant="outlined"
                  aria-controls="submenu-1"
                  aria-haspopup="true"
                  onClick={(event) => setAnchorElSelect1(event.currentTarget)}
                >
                  {status ?? "Status"} <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="submenu-1"
                  anchorEl={anchorElSelect1}
                  open={Boolean(anchorElSelect1)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    // onClick={() => handleMenuItemClickSelect1(3)}
                    onClick={() => handleStatusSelect("active")}
                  >
                    Active Stake
                  </MenuItem>
                  <MenuItem
                    // onClick={() => handleMenuItemClickSelect1(4)}
                    onClick={() => handleStatusSelect("inactive")}
                  >
                    Inactive Stake
                  </MenuItem>
                  <MenuItem
                    // onClick={() => handleMenuItemClickSelect1(4)}
                    onClick={() => {
                      setStatus(null);
                      setAnchorElSelect1(null);
                    }}
                  >
                    Clear status
                  </MenuItem>
                </Menu>
                <Button
                  variant="outlined"
                  aria-controls="submenu-2"
                  aria-haspopup="true"
                  // onClick={handleMenuClickSelect2}
                  onClick={(event) => setAnchorElSelect2(event.currentTarget)}
                >
                  {pool ?? "Pool"} Days <ArrowDropDownIcon />
                </Button>

                <Menu
                  id="submenu-2"
                  anchorEl={anchorElSelect2}
                  open={Boolean(anchorElSelect2)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    // onClick={() => handleMenuItemClickSelect2(5)}
                    onClick={() => handlePoolSelect("7")}
                  >
                    7 Days
                  </MenuItem>
                  <MenuItem onClick={() => handlePoolSelect("30")}>
                    30 Days
                  </MenuItem>
                  <MenuItem onClick={() => handlePoolSelect("60")}>
                    60 Days
                  </MenuItem>
                  <MenuItem onClick={() => handlePoolSelect("90")}>
                    90 Days
                  </MenuItem>
                  <MenuItem onClick={() => handlePoolSelect("180")}>
                    180 Days
                  </MenuItem>
                  <MenuItem onClick={() => handlePoolSelect("360")}>
                    360 Days
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setPool(null);
                      setAnchorElSelect2(null);
                    }}
                  >
                    Clear Pool
                  </MenuItem>
                </Menu>
              </Tabs>
            </div>
          </Box>

          {
            <DayTable
              TableData={TableData}
              majorTokenPool={majorTokenPool}
              timeRange={timeRange}
              status={status}
              pool={pool}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          }

          {/* {mainTab === 0 && subTab === 1 && <WeekTable search={search} />}
          {mainTab === 0 && subTab === 2 && <MonthTable search={search} />}
          {mainTab === 1 && subTab === 0 && <DayTable search={search} />}
          {mainTab === 1 && subTab === 1 && <WeekTable search={search} />}
          {mainTab === 1 && subTab === 2 && <MonthTable search={search} />} */}
        </div>
      </section>

      {/* <Expirytable /> */}
    </>
  );
};

export default AdmReport2;
