import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from "@mui/material";
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { shortAddress, toLocale } from '../hooks/useContract';
import { formatEther, formatUnits } from 'viem';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SortIcon from '@mui/icons-material/Sort';
import { GetCoinByPool, UsePoolData } from '../hooks/useContractData';
import { useGraphData } from '../hooks/useGraph';
import EarningDetails from './refIncomeDetails';

export default function CollapsibleTable(props) {
  const data = props.props
  const navigate = useNavigate()
  const { fetchDetails } = useGraphData()

  const { poolDetails } = UsePoolData()
  // Search Filter Data
  const [search, setSearch] = useState();
  const [filteredLocations, setFilteredLocations] = useState(data);

  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' for ascending and 'desc' for descending
  const [sortField, setSortField] = useState(''); // column/field to sort by
  const [sortCycle, setSortCycle] = useState(0);  // 0 for "Active", 1 for "Completed", 2 for "Removed"


  useEffect(() => {
    if (search && data !== undefined) {
      setFilteredLocations(
        data.filter(location =>
          (location.giver ?? '').toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredLocations(data);
    }
  }, [search, data]);

  const [pagination, setPagination] = useState(0);
  const len = filteredLocations?.length

  //  Code for pagination 
  const [indexOfLast, setindexOfLast] = useState(10)
  const [indexOfFirst, setindexOfFirst] = useState(0)

  function pagechange(event, value) {
    const currentPage = Number(value)
    const dataPerPage = 10

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo)
    setindexOfLast(indexOfLastTodo)
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(len / 10)
    if (!isNaN(PageCount)) { // Ensure PageCount is a valid number before setting it
      setPagination(PageCount);
    }
  }

  useEffect(() => {
    pageHandler()
  }, [len])


  const handleSort = (field) => {
    if (sortField === field) {
      if (field === 'status') {
        setSortCycle((prevCycle) => (prevCycle + 1) % 3);
      } else {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      }
    } else {
      setSortField(field);
      setSortDirection('asc');
      if (field === 'status') setSortCycle(0);
    }
  };


  const daysSort = (a, b, direction) => {
    const daysA = poolDetails.find(item => item.pool.toLowerCase() === a.poolAddress.toLowerCase())?.days || 0;
    const daysB = poolDetails.find(item => item.pool.toLowerCase() === b.poolAddress.toLowerCase())?.days || 0;
    return direction === 'asc' ? daysA - daysB : daysB - daysA;
  };


  const numberSort = (a, b, direction) => {
    return direction === 'asc' ? a - b : b - a;
  };

  const stakeAmountSort = (a, b, direction) => {
    const valueA = formatUnits(a.stakeAmount, GetCoinByPool(poolDetails, a?.poolAddress).coin === "TMT" ? 18 : 6);
    const valueB = formatUnits(b.stakeAmount, GetCoinByPool(poolDetails, b?.poolAddress).coin === "TMT" ? 18 : 6);
    return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
  };

  useEffect(() => {
    if (data !== undefined && sortField) {
      const sortedData = [...data].sort((a, b) => {
        switch (sortField) {
          case 'earned':
            return numberSort(a.amount, b.amount, sortDirection);
          case 'level':
            return numberSort(a.level, b.level, sortDirection);
          case 'stakeAmount':
            return stakeAmountSort(a, b, sortDirection);
          case 'days':
            return daysSort(a, b, sortDirection);
          case 'status':
            const order = ['Active', 'Completed', 'Removed'];
            const currentPriorityStatus = order[sortCycle];
            return a.status === currentPriorityStatus ? -1 : b.status === currentPriorityStatus ? 1 : 0;

          default:
            return 0;
        }
      });
      setFilteredLocations(sortedData);
    }
  }, [sortField, sortDirection, data, sortCycle]);


  const notify = () => toast.success("Copy Successfully")


  return (
    <>
      <section className='position-relative tablearea'>

        <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
          <form className="form" onSubmit={(e) => e.preventDefault()}>

            <label htmlFor="search">
              <input
                className="input"
                type="text"
                required=""
                placeholder="Wallet Address"
                id="search"
                onChange={(e) => setSearch(e.target.value)}
                autoComplete='off'
              />
              <div className="fancy-bg" />
              <div className="search">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                >
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                  </g>
                </svg>
              </div>
              <button className="close-btn" type="reset" onClick={() => setSearch()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </label>
          </form>
          <div className="backsidebtn" onClick={() => navigate("/")}>
            <ArrowBackIcon />
          </div>
        </div>
        <TableContainer component={Paper} sx={{ marginTop: "50px" }}>
          <Table aria-label="collapsible table">
            <TableHead className='incometittle'>
              <TableRow>
                <TableCell >Sr No</TableCell>
                <TableCell align="center">Wallet address</TableCell>
                <TableCell align="center" onClick={() => handleSort('level')}>Level  {sortField === 'level' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</TableCell>
                {/* <TableCell align="center" onClick={() => handleSort('status')}>Status <SortIcon /></TableCell> */}
                <TableCell align="center" onClick={() => handleSort('stakeAmount')}>Stake Amount
                  {sortField === 'stakeAmount' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </TableCell>
                <TableCell align="center" onClick={() => handleSort('days')}>Pool
                  {sortField === 'days' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </TableCell>
                <TableCell align="center" onClick={() => handleSort('earned')} >Earning received
                  {sortField === 'earned' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLocations ? filteredLocations.slice(indexOfFirst, indexOfLast).map((row, index) => {
                return (<>
                  <TableRow className='incometable' >
                    <TableCell component="th" scope="row">
                      {index + indexOfFirst + 1}
                    </TableCell>
                    <CopyToClipboard text={row?.giver}>
                      <TableCell align="center" onClick={notify}>
                        <span className='d-flex align-items-center justify-content-center'>
                          {shortAddress(row.giver)}<CopyAllIcon sx={{ width: "17px", marginLeft: "3px" }} />
                        </span>
                      </TableCell>
                    </CopyToClipboard>
                    <TableCell align="center">{row?.level}</TableCell>
                    {/* <TableCell align="center" ><div className='statushighlight'>{row?.status}</div></TableCell> */}
                    <TableCell align="center">{formatUnits(row?.stakeAmount, GetCoinByPool(poolDetails, row?.poolAddress).coin === "TMT" ? 18 : 6)} {GetCoinByPool(poolDetails, row?.poolAddress).coin === "TMT" ? "TMT" : "USDT"}</TableCell>
                    <TableCell align="center">{row?.DaysToMature} Days</TableCell>
                    <TableCell align="center" className='d-flex justify-content-center'>
                      <span className='d-flex align-items-center'>
                        {toLocale(formatEther(row?.amount))} TMT
                      </span>
                      <span className='ms-1 d-flex align-items-center'>
                        {formatEther(row?.amount) > 0 && <EarningDetails setCon={() => fetchDetails({ reffered: row?.giver, poolAddress: row?.poolAddress })} poolDetails={poolDetails} />}
                      </span>
                    </TableCell>
                  </TableRow>
                </>)
              }) : <>

              </>}
            </TableBody>
          </Table>
          {filteredLocations?.length > 0 ? "" :
            <div className="nodata">
              <h4>No Data Found</h4>
            </div>
          }
        </TableContainer>
        {pagination <= 1 ?
          ''
          : <>
            <Pagination className='myPagination' onChange={pagechange} count={pagination} color="secondary" />
          </>
        }

      </section>
    </>

  );
}