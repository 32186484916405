import React, { useState } from "react";
import { saveAs } from "file-saver";
// import * as XLSX from "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js";

const formatStake = (stake, swapRate, majorTokenPool) => {
  const {
    user,
    amount,
    expiryTimestamp,
    pool,
    referalWallet,
    totalReward,
    blockTimestamp,
    transactionHash,
  } = stake;

  const convertTimeStampToDate = (timestamp) => {
    timestamp = parseInt(timestamp);
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${day}/${month}/${year}`;
  };

  const txLink = `https://polygonscan.com/tx/${transactionHash}`;

  return {
    Wallet: user,
    "Coin Staked Date": convertTimeStampToDate(blockTimestamp),
    "Stake Pool": pool + " " + majorTokenPool,
    "Stake Expiry Date": convertTimeStampToDate(expiryTimestamp),
    "Stake amount": parseFloat(amount).toFixed(4) + " " + majorTokenPool,
    "Referral Wallet": referalWallet,
    "Total reward in TMT": parseFloat(totalReward).toFixed(4),
    "Total Reward in USDT": (parseFloat(totalReward) * swapRate).toFixed(4),
    "Transaction Link": txLink,
  };
};

let XLSX;

const loadSheetJS = async () => {
  const script = document.createElement("script");
  script.src =
    "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js";
  script.async = false;
  document.body.appendChild(script);

  return new Promise((resolve) => {
    script.onload = () => {
      XLSX = window.XLSX;
      resolve();
    };
  });
};

const createExcelFile = async (stakes, swapRate, majorTokenPool) => {
  await loadSheetJS();

  //   stakes = stakes.reverse();

  const formattedstakes = stakes.map((stake)=>formatStake(stake, swapRate, majorTokenPool));

  const firstTxTimestamp = stakes[stakes.length - 1].blockTimestamp;
  const lastTxTimestamp = stakes[0].blockTimestamp;
  const formatFirstTxDate = new Date(
    firstTxTimestamp * 1000
  ).toLocaleDateString();
  const formatLastTxDate = new Date(
    lastTxTimestamp * 1000
  ).toLocaleDateString();

  const worksheet = XLSX.utils.json_to_sheet(formattedstakes, {
    header: [
      "Wallet",
      "Coin Staked Date",
      "Stake Pool",
      "Stake Expiry Date",
      "Stake amount",
      "Referral Wallet",
      "Total reward in TMT",
      "Total Reward in USDT",
      "Transaction Link",
    ],
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Stakes");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const fileName = `stakes-${formatFirstTxDate}--${formatLastTxDate}.xlsx`;

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, fileName);

  console.log("Excel file created successfully!");
};

export default createExcelFile;
