import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../Components/Header/Header.jsx";
import Footer from "../Components/Footer/Footer.jsx";



const Layout = () => {
  return (
    <>
      <Header />
     
      <main>
        {/* <div className="borderboxmain">
          <img className="linetopleft" src={topborder} alt="" />
          <img className="linebottomright" src={bottomborder} alt=""/>
        </div> */}
        <Outlet />
      <Footer />
      </main>
     
    </>
  );
};

export default Layout;
