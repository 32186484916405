import { CRow, CCol } from '@coreui/react';
import { useGlobalData } from '../hooks/globalData';
import { toLocale } from '../hooks/useContract';
import { formatEther } from 'ethers/lib/utils';
import { formatUnits } from 'viem';
import { GetCoinByPool, UsePoolData } from '../hooks/useContractData';



export const GlobalData = () => {
    const { totalStaked, data } = useGlobalData()
    const { poolDetails } = UsePoolData()

    const calculateTotal = (array, fieldName) => {

        const stakedAmounts = array.map(item => Number(formatEther(item[fieldName])));

        const totalStakedAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);

        return totalStakedAmount;
    };


    const calculateTotal2 = (array, coin, fieldName) => {
        const test = poolDetails.filter(item => item.coin === coin);

        const testPoolAddresses = new Set(test.map(item => item.pool.toLowerCase()));

        const matchedItems = array.filter(item => testPoolAddresses.has(item.poolAddress.toLowerCase()));

        const stakedAmounts = matchedItems.map(item => {
            const coinType = GetCoinByPool(poolDetails, item.poolAddress).coin;
            const decimals = coinType === "TMT" ? 18 : 6;
            return Number(formatUnits(item[fieldName], decimals));
        });

        // Sum up the staked amounts
        const totalStakedAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);

        return totalStakedAmount;
    };


    const totalZebStake = calculateTotal2(totalStaked, "TMT", 'amount');
    const totalUsdtStake = calculateTotal2(totalStaked, "USDT", 'amount');
    const totalZebClaim = calculateTotal(data, 'claimedAmount');

    return (

        <CCol className='referalpx' md="12">

            <CRow xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 4 }} className="justify-content-center gap-4">
                <CCol className='mb-2 adv-item'>
                    <div className='inrInfoBx d-block adv-info justify-content-center '>

                        <div className="refbox">
                            <p>Total TMT Staked</p>
                            <strong>
                                {toLocale(totalZebStake)}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2 adv-item '>
                    <div className='inrInfoBx d-block adv-info'>
                        <div className="refbox">
                            <p>Total TMT Claimed</p>
                            <strong>
                                {toLocale(totalZebClaim)}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2 adv-item'>
                    <div className='inrInfoBx d-block adv-info'>
                        <div className="refbox">
                            <p>Total USDT Staked</p>
                            <strong>
                                {toLocale(totalUsdtStake)}
                            </strong>

                        </div>
                    </div>
                </CCol>


            </CRow>
        </CCol>
    )
}