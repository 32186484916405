import { providers } from "ethers"
import Web3 from "web3"

// COIN  ADDRESS
export const CoinAddress = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f";  // TMT TOKEN 
export const USDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";         // USDT TOKEN 

// ORACLE 
export const OracleAddress = "0x8Fc27BdD76EB8627F1CfCD118568545d138615D5"


// Mainnet POOLS
export const PoolDWC9 =  "0x09b4b75c7b24c2c279819f8b2684c958db54ca6f"
export const PoolDWC30 = "0x1e23e0254cb322d40c10594625ea8db82a218c2d"
export const PoolDWC90 = "0x386262e1dd6a9235b2c5facb3b358483111f4c40"
export const PoolDWC180 ="0x693Dd1D9a1B9B257a087A6021275108EEB8812e6"
export const PoolDWC270 ="0x5FA341f0e7D97Bfb6b80bC3125F41599B9ed9493"
export const PoolDWC360 ="0x7116D1671979d662938aD3cC6D1BDcd6b59eE7BA"

export const PoolUSDT9 =  "0xC804f62f1cb403E389a73F44bBeb098bBCcF759a"
export const PoolUSDT30 = "0x297cb163e8dbCf81CF8922cf3f76dC6822830913"
export const PoolUSDT90 = "0xDf6D6c6CF3327bD32E050B332fc3e31E640528B5"
export const PoolUSDT180 ="0x3F413AbA25B9c8ef9C58E2d71ceA2635B9000114"
export const PoolUSDT270 ="0xB9B92Bb8F9AD4CEDF3DCf6456b1387643A916dAe"
export const PoolUSDT360 ="0x5a616F547590312f7b34A16D9b6d1119F16c4980"


export const LockingPeriod = 7  //sec 7days for real

export const graphQlUrl =
  "https://api.studio.thegraph.com/query/76981/tmt-stake-api/version/latest";
  // "https://api.studio.thegraph.com/query/60406/defiworld-stake-thegraph-t/version/latest";

export const transactionUrl = "https://polygonscan.com/tx/" 

export const chainRPC =
  "https://polygon-rpc.com";

export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 137


// URL used in project 
export const url = "https://stake.techmont.finance/";   // referral url
export const polygonchainRPC = "https://polygon-rpc.com"


// export const poolDetails = [
//     { pool: PoolDWC7, percent: 6.39, days: 7, coin: "TMT",label: "TMT-7" },
//     { pool: PoolDWC30, percent: 8.21, days: 30, coin: "TMT",label: "TMT-30" },
//     { pool: PoolDWC90, percent: 11.01, days: 90, coin: "TMT",label: "TMT-90" },
//     { pool: PoolDWC180, percent: 14.21, days: 180, coin: "TMT",label: "TMT-180" },
//     { pool: PoolDWC360, percent: 18.08, days: 360, coin: "TMT",label: "TMT-360" },
//     { pool: PoolUSDT7, percent: 4.25, days: 7, coin: "USDT",label: "USDT-7" },
//     { pool: PoolUSDT30, percent: 6.39, days: 30, coin: "USDT",label: "USDT-30" },
//     { pool: PoolUSDT90, percent: 8.21, days: 90, coin: "USDT",label: "USDT-90" },
//     { pool: PoolUSDT180, percent: 10.08, days: 180, coin: "USDT",label: "USDT-180" },
//     { pool: PoolUSDT360, percent: 12.01, days: 360, coin: "USDT",label: "USDT-360" },
// ]



// TEST POOLS
// export const PoolDWC7 = "0x832eF27fFdcDBFa860dccdD72a5B0174a6a8dBC6"
// export const PoolDWC30 = "0xF78a3697D214631F2fbb1aa37fD7a1226116C842"
// export const PoolDWC90 = "0x419FAb73c4610a58e04C37d9F472e9FDFc22418B"
// export const PoolDWC180 = "0x103007C13313BCf82bF463AC9ab5c0BAB4E0c5Ed"
// export const PoolDWC360 = "0x4E5eFCDF83b76191DF9E5aaBe7DFDE877Df4c11D"

// export const PoolUSDT7 = "0x1A558c1dB88c46B6e2Ff62EFF4AdbeE38e54b2a8"
// export const PoolUSDT30 = "0x79002730EE665410413AA1be32a56Ff1F226EA07"
// export const PoolUSDT90 = "0x0877683138ABc7596f1e2bd7EbD000b587F856B3"
// export const PoolUSDT180 = "0x99F73513249D43d3A98ab1442c493D9e2b9D2214"
// export const PoolUSDT360 = "0x3619F7df6a1cF94158D4e82d4c7aAc2c32AEFfA3"