import { CRow, CCol } from '@coreui/react';
import Wallet from '../Assets/images/Icons/2referral-wallet.png';
import referral from '../Assets/images/Icons/2no-of-referral.png';
import link from '../Assets/images/Icons/2referral-link.png';
import { shortAddress, } from '../hooks/useContract';
import { ReferralOuter } from '../hooks/useContractData';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import { url } from '../config';
import { toast } from "react-hot-toast"
import { useNavigate } from 'react-router-dom';
import { useQueryAndSetAddress } from '../hooks/userAddress';


export const Referral = () => {
    const navigate = useNavigate()
    const { address } = useQueryAndSetAddress()
    const { referedBy, canRefer } = ReferralOuter();
    const notify = () => toast.success('Copied Successfully!')

    return (
        <CCol className='referalpx' md="12">
        <CRow xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 3 }} className="justify-content-center gap-4  " id="demo1">
          <CCol className="mb-2 adv-item ">
            <div className="inrInfoBx inrInfoBx1 adv-info">
              <div className="imginrBx">
                <img src={link} alt="Referral Link" />
              </div>
              <div className="refbox">
                <p>Referral link</p>
                <div className="inputvalue">
                  <div className="input-wrapper">
                    <CopyToClipboard text={`${url}?referrer=${address}`}>
                      <div className="inputbar">
                        {canRefer ? (
                          <span onClick={notify}>
                            {url.substring(0, 16)}
                            <CopyAllIcon style={{ fontSize: '1.2rem', marginLeft: '3px' }} />
                          </span>
                        ) : (
                          <span>Not Eligible</span>
                        )}
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
          
          <CCol className="mb-2 adv-item ">
            <div className="inrInfoBx inrInfoBx3 adv-info">
              <div className="imginrBx">
                <img src={Wallet} alt="Referral Wallet" />
              </div>
              <div className="refbox">
                <p>Referral Wallet</p>
                <strong>{shortAddress(referedBy !== 0 ? referedBy : "")}</strong>
              </div>
            </div>
          </CCol>
          
          <CCol className="mb-2 adv-item ">
            <div className="inrInfoBx inrInfoBx4 adv-info">
              <div className="imginrBx">
                <img src={referral} alt="No. of Referrals" />
              </div>
              <div className="refbox">
                <p>Referral reward</p>
                <button className="refclaimbtn" onClick={() => navigate("/account-overview?value=2")}>
                  VIEW
                </button>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCol>
      
    )
}
export default Referral;
