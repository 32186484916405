import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { shortAddress, toLocale } from "../hooks/useContract";
import { formatUnits } from "viem";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CopyToClipboard from "react-copy-to-clipboard";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
import { transactionUrl } from "../config";
import { dateCon } from "../config/helper";
import { AdminGraph } from "../hooks/adminGraph";
import { CRow, CCol } from "@coreui/react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CContainer } from "@coreui/react";
import { GetCoinByPool, UsePoolData } from "../hooks/useContractData";


// CheckboxItem component
const CheckboxItem = ({ name, value, label, onChange }) => (
  <label>
    <input
      type="checkbox"
      name={name}
      value={value}
      onChange={onChange}
    />
    {label}
  </label>
);

// Configuration for checkboxes
const checkboxConfig = {
  tmt: [9, 30, 90, 180, 270, 360],
  usdt: [9, 30, 90, 180, 270, 360],
};
const AdminReport = () => {

  const { data } = AdminGraph();
  const { poolDetails } = UsePoolData()

  // Search Filter Data
  const [search, setSearch] = useState();
  const [filteredLocations, setFilteredLocations] = useState(data);
  const [StakeToken, setStakeToken] = useState("");
  const [Zebmenu, setZebmenu] = useState(false);
  const [Usdtmenu, setUsdtmenu] = useState(false);
  const [coinName, setName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([]);


  // Function to apply the selected filters
  const applyFilters = () => {
    let filteredData = data;

    // Apply search filter if search term is provided
    if (search) {
      filteredData = filteredData.filter((location) =>
        (location.user ?? "").toLowerCase().includes(search.toLowerCase())
      );
    }

    // Apply  filters based on selectedFilters array
    if (selectedFilters.length > 0) {
      filteredData = filteredData.filter(location => {
        const daysToMature = location.DaysToMature ?? "";
        const isDaysToMatureMatch = daysToMature.split(",").some(value => selectedFilters.includes(value));

        const poolCoin = GetCoinByPool(poolDetails, location.poolAddress ?? "").coin;
        const isStakeTokenMatch = poolCoin === StakeToken;

        return isDaysToMatureMatch && isStakeTokenMatch;
      });
    }


    setFilteredLocations(filteredData);

  };

  // Handle filter option change
  const handleFilterChange = (event) => {
    const filterValue = event.target.value;
    const isChecked = event.target.checked;
    const name = event.target.name;
    setindexOfFirst(0);
    setindexOfLast(10);
    setCurrentPage(1);
    if (name === coinName) {
      if (isChecked) {
        // Check if filterValue is not already in selectedFilters before adding it
        if (!selectedFilters.includes(filterValue)) {
          setSelectedFilters([...selectedFilters, filterValue]);
        }
      } else {
        setSelectedFilters(
          selectedFilters.filter((filter) => filter !== filterValue)
        );
      }
    } else {
      // If the name is not equal to coinName, reset selectedFilters
      setSelectedFilters([filterValue]);
      setName(name);
    }
  };

  const calculateTotal2 = (array, coin, fieldName) => {
    const test = poolDetails.filter(item => item.coin === coin);

    const testPoolAddresses = new Set(test.map(item => item.pool.toLowerCase()));

    const matchedItems = array.filter(item => testPoolAddresses.has(item.poolAddress.toLowerCase()));
    const length = matchedItems.length

    const stakedAmounts = matchedItems.map(item => {
      const coinType = GetCoinByPool(poolDetails, item.poolAddress).coin;
      const decimals = coinType === "TMT" ? 18 : 6;
      return Number(formatUnits(item[fieldName], decimals));
    });


    // Sum up the staked amounts
    const totalStakedAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);

    return { totalStakedAmount, length };
  };

  const totalDWCStake = calculateTotal2(data, "TMT", 'amount');
  const totalUSDTStake = calculateTotal2(data, "USDT", 'amount');

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    applyFilters();
  }, [search, selectedFilters, data]);

  //  Code for pagination
  const [pagination, setPagination] = useState(0);
  const len = filteredLocations?.length;
  const [indexOfLast, setindexOfLast] = useState(10);
  const [indexOfFirst, setindexOfFirst] = useState(0);

  function pagechange(event, value) {
    const currentPage = Number(value);
    setCurrentPage(currentPage);
    const dataPerPage = 10;

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo);
    setindexOfLast(indexOfLastTodo);
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(len / 10);
    setPagination(PageCount);
  };

  useEffect(() => {
    pageHandler();
  }, [len]);


  const handleZebCheckboxChange = () => {
    const usdtCheckboxes = document.getElementsByName("usdt");
    usdtCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

  };

  const handleUsdtCheckboxChange = () => {
    const zebCheckboxes = document.getElementsByName("tmt");
    zebCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });


  };



  const handleCheckboxChange = (e, type) => {
    handleFilterChange(e)

    if (type === 'tmt') {
      handleZebCheckboxChange();
      setStakeToken("TMT");
    } else if (type === 'usdt') {
      handleUsdtCheckboxChange();
      setStakeToken("USDT");
    }

  };

  const notify = () => toast.success("Copy Successfully");

  return (
    <section className="adminsec">
      <CContainer>

        <h1 className=" admintittle  ">Stake History</h1>
        <CRow
          xs={{ cols: 1 }}
          md={{ cols: 2 }}
          lg={{ cols: 4 }}  
          className="justify-content-center mt-4 "
        >
          <CCol className="mb-2 ">

            <div className="inrInfoBx inrInfoBx3">
              <div className="refbox">
                <p>Total TMT Staked</p>
                <strong>{toLocale(totalDWCStake.totalStakedAmount)} TMT</strong>
              </div>
            </div>
          </CCol>
          <CCol className="mb-2">
            <div className="inrInfoBx inrInfoBx4">
              <div className="refbox">
                <p>Total Stakes in TMT</p>
                <strong>{totalDWCStake.length}</strong>
              </div>
            </div>
          </CCol>
          <CCol className="mb-2">
            <div className="inrInfoBx inrInfoBx1">
              <div className="refbox">
                <p>Total USDT Staked</p>
                <strong>{toLocale(totalUSDTStake.totalStakedAmount)} USDT</strong>
              </div>
            </div>
          </CCol>

          <CCol className="mb-2">
            <div className="inrInfoBx inrInfoBx4">
              <div className="refbox">
                <p>Total Stakes in USDT</p>
                <strong>{totalUSDTStake.length}</strong>
              </div>
            </div>
          </CCol>
        </CRow>

        <div className="searchbox flex justify-content-center align-items-center flex-row-reverse mb-5 mt-4">
          <form className="form" onSubmit={(e) => e.preventDefault()}>
            <label htmlFor="search">
              <input
                className="input"
                type="text"
                required=""
                placeholder="Wallet Address"
                id="search"
                onChange={handleSearchChange}
                autoComplete="off"
              />
              <div className="fancy-bg" />
              <div className="search">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                >
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                  </g>
                </svg>
              </div>
              <button
                className="close-btn"
                type="reset"
                onClick={() => setSearch()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </label>
          </form>
        </div>

        <div>
          <h4 className="flex justify-content-center fs-2">Filter Pool</h4>
          <div className="flex justify-content-center my-3">
            <button
              className="clearbtn"
              onClick={() => {
                setSelectedFilters([])
                handleZebCheckboxChange();
                handleUsdtCheckboxChange();
              }}
            >
              Clear
            </button>
          </div>
          <div className="filterclass">
            <div className="zebarea">
              <button className="zebfilter" onClick={() => setZebmenu(!Zebmenu)}>
                TMT <ArrowDropDownIcon />
              </button>
              <div className={`${Zebmenu ? "d-flex" : "d-none"} flex-column filterbox`}>
                {checkboxConfig.tmt.map((value) => (
                  <CheckboxItem
                    key={`TMT-${value}`}
                    name="tmt"
                    value={value}
                    label={`TMT-${value}`}
                    onChange={(e) => handleCheckboxChange(e, 'tmt')}
                  />
                ))}
              </div>
            </div>
            <div className="usdtarea">
              <button className="usdtfilter" onClick={() => setUsdtmenu(!Usdtmenu)}>
                USDT <ArrowDropDownIcon />
              </button>
              <div className={`${Usdtmenu ? "d-flex" : "d-none"} flex-column filterbox`}>
                {checkboxConfig.usdt.map((value) => (
                  <CheckboxItem
                    key={`usdt-${value}`}
                    name="usdt"
                    value={value}
                    label={`USDT-${value}`}
                    onChange={(e) => handleCheckboxChange(e, 'usdt')}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <TableContainer component={Paper} sx={{ marginTop: "50px" }}>
          <Table aria-label="collapsible table">
            <TableHead className="incometittle">
              <TableRow>
                <TableCell>Sr No</TableCell>
                <TableCell align="center">Wallet Address</TableCell>
                <TableCell align="center">Stake Amount</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Pool</TableCell>
                <TableCell align="center">Tx Hash</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLocations ? (
                filteredLocations
                  .slice(indexOfFirst, indexOfLast)
                  .map((row, i) => {
                    return (
                      <>
                        <TableRow className="incometable">
                          <TableCell component="th" scope="row">
                            {i + indexOfFirst + 1}
                          </TableCell>
                          <CopyToClipboard text={row?.user}>
                            <TableCell align="center" onClick={notify}>
                              <span className="d-flex align-items-center justify-content-center">
                                {shortAddress(row.user)}
                                <CopyAllIcon
                                  sx={{ width: "17px", marginLeft: "3px" }}
                                />
                              </span>
                            </TableCell>
                          </CopyToClipboard>
                          <TableCell align="center">
                            {toLocale(
                              formatUnits(
                                row?.amount,
                                GetCoinByPool(poolDetails, row?.poolAddress).coin === "TMT" ? 18 : 6
                              )
                            )}{" "}
                            {GetCoinByPool(poolDetails, row?.poolAddress).coin}
                          </TableCell>
                          <TableCell align="center" >
                            {dateCon(row.blockTimestamp)}
                          </TableCell>
                          <TableCell align="center" >
                            <button className="poolbtn">
                              {GetCoinByPool(poolDetails, row?.poolAddress).label}
                            </button>
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              style={{ textDecoration: "none", color: "#fff" }}
                              to={transactionUrl + row?.transactionHash}
                              target="blank"
                            >
                              <span className="d-flex align-items-center justify-content-center">
                                {shortAddress(row.transactionHash)}
                                <LaunchIcon
                                  sx={{
                                    width: "16px",
                                    marginLeft: "3px",
                                    color: "#2c70b3",
                                  }}
                                />
                              </span>
                            </Link>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          {filteredLocations?.length > 0 ? (
            ""
          ) : (
            <div className="nodata">
              <h4>No Data Found</h4>
            </div>
          )}
        </TableContainer>

        {pagination <= 1 ? (
          ""
        ) : (
          <>
            <Pagination
              className="myPagination"
              onChange={pagechange}
              count={pagination}
              color="secondary"
              page={currentPage}
            />
          </>
        )}

      </CContainer>
    </section>
  );
};

export default AdminReport;
