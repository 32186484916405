import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { transactionUrl } from '../config';
import { shortAddress, toLocale } from '../hooks/useContract';
import LaunchIcon from '@mui/icons-material/Launch';
import { formatEther, formatUnits } from 'viem';
import { GetCoinByPool } from '../hooks/useContractData';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function EarningDetails({ setCon, poolDetails }) {

    const [open, setOpen] = useState(false);
    const [filteredLocations, setFilteredLocations] = useState([]);

    const handleClickOpen = async() => {
        setOpen(true);
         const test = await setCon()
         setFilteredLocations(test)
    };
    const handleClose = () => {
        setOpen(false);
    };


    const [pagination, setPagination] = useState(0);
    const len = filteredLocations?.length

    //  Code for pagination 
    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }
    const pageHandler = () => {
        const PageCount = Math.ceil(len / 10)
        if (!isNaN(PageCount)) { // Ensure PageCount is a valid number before setting it
            setPagination(PageCount);
        }
    }

    useEffect(() => {
        pageHandler()
    }, [len])



    return (
        <>
            <InfoIcon onClick={handleClickOpen} sx={{width:"20px"}}/>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle className='text-center' sx={{ m: 0, p: 2,background:"#110f21",color:"#fff" }} id="customized-dialog-title">
                    Earning Transaction
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <TableContainer component={Paper} >
                    <Table aria-label="collapsible table">
                        <TableHead className='incometittle'>
                            <TableRow>
                                <TableCell >Sr No</TableCell>
                                <TableCell align="center">TransHash</TableCell>
                                <TableCell align="center" >StakeAmount</TableCell>
                                <TableCell align="center" >Earning Recieved
                                </TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredLocations ? filteredLocations.slice(indexOfFirst, indexOfLast).map((row, index) => {
                                return (<>
                                    <TableRow className='incometable' >
                                        <TableCell component="th" scope="row">
                                            {index + indexOfFirst + 1}
                                        </TableCell>

                                        <TableCell align="center"><Link style={{ textDecoration: "none", color: '#fff' }} to={transactionUrl + row?.transactionHash} target="blank" >
                                            <span className='d-flex align-items-center justify-content-center'>
                                                {shortAddress(row?.transactionHash)}<LaunchIcon sx={{ width: "16px", marginLeft: "3px", color: "#2c70b3" }} />
                                            </span>
                                        </Link></TableCell>
                                        <TableCell align="center">{toLocale(formatUnits(row?.stakeAmount, GetCoinByPool(poolDetails, row?.poolAddress).coin === "TMT" ? 18 : 6))} {GetCoinByPool(poolDetails, row?.poolAddress).coin === "TMT" ? "TMT" : "USDT"}</TableCell>

                                        <TableCell align="center" >{toLocale(formatEther(row?.amount))} TMT</TableCell>
                                    </TableRow>
                                </>)
                            }) : <>

                            </>}
                        </TableBody>
                    </Table>
                    {filteredLocations?.length > 0 ? "" :
                        <div className="nodata">
                            <h4>No Data Found</h4>
                        </div>
                    }
                </TableContainer>
                {pagination <= 1 ?
                    ''
                    : <>
                        <Pagination className='myPagination' onChange={pagechange} count={pagination} color="secondary" />
                    </>
                }


            </BootstrapDialog>
        </>
    );
}