import React, { useRef, useState } from "react";
import "../Assets/css/Admin.css";
import {
  AdminFunction,
  ContractBalance,
  GetCoinByPool,
  ReferralOuter,
  UsePoolData,
} from "../hooks/useContractData";
import { toLocale } from "../hooks/useContract";
import {
  PoolDWC180,
  PoolDWC30,
  PoolDWC360,
  PoolDWC9,
  PoolDWC270,
  PoolDWC90,
  PoolUSDT180,
  PoolUSDT270,
  PoolUSDT30,
  PoolUSDT360,
  PoolUSDT9,
  PoolUSDT90,
  CoinAddress,
  USDT,
} from "../config";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MenuItem, Select } from "@mui/material";

const LiquidityForm = ({
  poolAddress,
  actionLabel,
  actionFunction,
  placeholder,
  enable,
}) => (
  <div className="os_row align-items-center boxpart">
    <div className="leftbx">
      <strong style={{ display: "flex", flexWrap: "wrap" }}>
        {actionLabel}{" "}
      </strong>
    </div>

    <div className="inputbox">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          actionFunction(
            e.target.token.value,
            e.target.amount.value,
            poolAddress
          );
        }}
      >
        <input
          className="inputswap"
          type="text"
          name="amount"
          autoComplete="off"
          placeholder={placeholder}
        />
        <select className="inputswapbtn" name="token" id="">
          <option value={CoinAddress}>TMT</option>
          <option value={USDT}>USDT</option>
        </select>
        <button type="submit" disabled={enable}>
          Submit
        </button>
      </form>
    </div>
  </div>
);

const AdminPanel = () => {
  const {
    AddLiquidity,
    EmergencyWithdraw,
    SetpercentageReturn,
    SetReferralPercentage,
    enable,
  } = AdminFunction();
  const { referralArray } = ReferralOuter();
  const { balance, balance2 } = ContractBalance();
  const [value1, setValue1] = useState("TMT");
  const [value, setValue] = useState(PoolDWC9);
  const [pool, setPool] = useState(PoolDWC9);
  const [level, setLevel] = useState(0);
  const selectRef = useRef(null);
  const { poolDetails } = UsePoolData();

  const handleChange = (event, newValue) => {
    setPool(newValue);
    setValue(newValue);
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    if (newValue === "TMT") {
      setValue(PoolDWC9);
      setPool(PoolDWC9);
    } else {
      setValue(PoolUSDT9);
      setPool(PoolUSDT9);
    }
  };

  return (
    <>
      <section className="mainbox adminform">
        <div className="os_container">
          <div className="tabsection">
            <div className="tabbar2 flex">
              <Tabs
                value={value1}
                onChange={handleChange1}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
              >
                <Tab label="TMT" value={"TMT"} />
                <Tab label="USDT" value={"USDT"} />
              </Tabs>
            </div>
            {value1 === "TMT" ? (
              <div className="tabbar2 flex">
                <Tabs
                  value={value}
                  ref={selectRef}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile={true}
                >
                  <Tab label="TMT-9" value={PoolDWC9} />
                  <Tab label="TMT-30" value={PoolDWC30} />
                  <Tab label="TMT-90" value={PoolDWC90} />
                  <Tab label="TMT-180" value={PoolDWC180} />
                  <Tab label="TMT-270" value={PoolDWC270} />
                  <Tab label="TMT-360" value={PoolDWC360} />
                </Tabs>
              </div>
            ) : (
              <div className="tabbar2 flex">
                <Tabs
                  value={value}
                  ref={selectRef}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile={true}
                >
                  <Tab label="USDT-9" value={PoolUSDT9} />
                  <Tab label="USDT-30" value={PoolUSDT30} />
                  <Tab label="USDT-90" value={PoolUSDT90} />
                  <Tab label="USDT-180" value={PoolUSDT180} />
                  <Tab label="USDT-270" value={PoolUSDT270} />
                  <Tab label="USDT-360" value={PoolUSDT360} />
                </Tabs>
              </div>
            )}
          </div>

          <div className="boxmain">
            <div className="boxinner">
              <h5 className="mb-3 ">
                {" "}
                Pool : {GetCoinByPool(poolDetails, pool).label}
              </h5>
              <span
                className="d-block mb-2"
                style={{ color: "#ff0000", fontSize: "16px" }}
              >
                Bal: {toLocale(balance[pool] || "0")} TMT
              </span>
              <span
                className="d-block mb-2"
                style={{ color: "#ff0000", fontSize: "16px" }}
              >
                Bal: {toLocale(balance2[pool] || "0")} USDT
              </span>
              <LiquidityForm
                poolAddress={pool}
                actionLabel={`Add Liquidity`}
                actionFunction={AddLiquidity}
                placeholder={"Enter Amount"}
                enable={enable}
              />
              <LiquidityForm
                poolAddress={pool}
                actionLabel={`Withdraw`}
                actionFunction={EmergencyWithdraw}
                placeholder={"Enter Amount"}
                enable={enable}
              />
              {/* <LiquidityForm
                poolAddress={pool}
                actionLabel={`Set ARP (%)`}
                actionFunction={SetpercentageReturn}
                placeholder={`Current ${GetCoinByPool(poolDetails, pool).percent} %`}
                enable={enable}

              /> */}
            </div>
          </div>
        </div>
        <div className="os_container mt-5">
          {/* <div className="boxmain">
            <div className="boxinner">
              <h5 className="mb-3 "> Set Referral Percentage</h5>
              <div className="os_row align-items-center boxpart">
                <div className="leftbx">
                  <strong style={{ display: "flex", flexWrap: "wrap" }}>
                    Select Level
                  </strong>
                </div>

                <div className="inputbox">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      SetReferralPercentage(e.target.level.value, e.target.percent.value);
                    }}
                    className="flex-column"
                  >
                    <Select
                      className="inputswap w-100"
                      name="level"
                      defaultValue={0}
                      onChange={(e)=>setLevel(e.target.value)}
                    >   
                      <MenuItem value={0}>1</MenuItem >
                      <MenuItem value={1}>2</MenuItem >
                      <MenuItem value={2}>3</MenuItem >
                    </Select>

                    <div className="leftbx">
                      <strong style={{ display: "flex", flexWrap: "wrap" }}>
                        Set Percentage(%)
                      </strong>
                    </div>

                    <input
                      className="inputswap"
                      type="text"
                      name="percent"
                      autoComplete="off"
                      placeholder={`Current ${referralArray[level]} %`}
                    />
                    <button type="submit" disabled={enable}>Submit</button>
                  </form>
                </div>
              </div>

            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default AdminPanel;
