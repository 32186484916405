import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import {get24hrsPayoutAmount,getTotalPayoutAmount} from '../../hooks/adminReport2Graph'

export default function Analytics() {
  const [selectedPool, setSelectedPool] = useState("TMT");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("7");
  const [analyticsData, setAnalyticsData] = useState([0, 0]);

  const handleChangePool = (event, newValue) => {
    setSelectedPool(newValue);
    // When changing pool, reset the time period to "7"
    setSelectedTimePeriod("7");
  };

  const handleChangeTimePeriod = (event, newValue) => {
    setSelectedTimePeriod(newValue);
  };

  // Synchronize time period with pool selection
  useEffect(() => {
    setSelectedTimePeriod("7"); // Reset time period to "7" whenever pool changes
  }, [selectedPool]);

  // Define card box content based on selected pool and time period
  const cardBoxContent2 = {
    TMT: {
      7: "Total TMT Payout",
      30: "Total TMT Payout",
      60: "Total TMT Payout",
      90: "Total TMT Payout",
      180: "Total TMT Payout",
      360: "Total TMT Payout",
    },
    USDT: {
      7: "Total TMT Payout",
      30: "Total TMT Payout",
      60: "Total TMT Payout",
      90: "Total TMT Payout",
      180: "Total TMT Payout",
      360: "Total TMT Payout",
    },
  };
  const cardBoxContent = {
    TMT: {
      7: "Daily TMT Payout",
      30: " Daily TMT Payout",
      60: " Daily TMT Payout",
      90: " Daily TMT Payout",
      180: "Daily TMT Payout",
      360: "Daily TMT Payout",
    },
    USDT: {
      7: "Daily TMT Payout",
      30: "Daily TMT Payout",
      60: "Daily TMT Payout",
      90: "Daily TMT Payout",
      180: "Daily TMT Payout",
      360: "Daily TMT Payout",
    },
  };

  useEffect(() => {
    let handleAnalyticsData = async () => {
      let totalStake = await get24hrsPayoutAmount(
        selectedPool,
        selectedTimePeriod
      );
      let totalStake2 = await getTotalPayoutAmount(
        selectedPool,
        selectedTimePeriod
      );
      setAnalyticsData([totalStake, totalStake2]);
    };

    handleAnalyticsData();
  }, [selectedPool, selectedTimePeriod]);

  // useEffect(()=>{
  //      console.log(' payoutData : ',analyticsData)
  // },[analyticsData])

  return (
    <>
      <section
        className="boxsmain"
        style={{ marginTop: 100, marginBottom: 40 }}
      >
        <div className="os_container mt-5">
          <div className="titleh2 mt-5 ">
            <h2>Payout History </h2>
          </div>
          <div className=" flex justify-content-center mt-5 mb-5">
            <Tabs
              value={selectedPool}
              onChange={handleChangePool}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
            >
              <Tab label="TMT" value="TMT" />
              <Tab label="USDT" value="USDT" />
            </Tabs>
          </div>

          <div className=" flex justify-content-center mb-5">
            <Tabs
              value={selectedTimePeriod}
              onChange={handleChangeTimePeriod}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
            >
              <Tab label="7 Days" value="7" />
              <Tab label="30 Days" value="30" />
              <Tab label="60 Days" value="60" />
              <Tab label="90 Days" value="90" />
              <Tab label="180 Days" value="180" />
              <Tab label="360 Days" value="360" />
            </Tabs>
          </div>

          {/* Render card box content */}
          <div className="os_row">
            <div className="col4">
              <div className="cardbox">
                <p>{cardBoxContent[selectedPool][selectedTimePeriod]}</p>
                <p>
                  {analyticsData[0]} {selectedPool}
                </p>
              </div>
            </div>

            <div className="col4">
              <div className="cardbox">
                <p>{cardBoxContent2[selectedPool][selectedTimePeriod]}</p>
                <p>
                  {analyticsData[1]} {selectedPool}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
