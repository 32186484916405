import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useQueryAndSetAddress } from "./userAddress";

export const AdminGraph = () => {
    const [data, setData] = useState([])
    const { address } = useQueryAndSetAddress()


    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {
                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{ 
                          stakeds (orderBy: blockTimestamp, orderDirection: desc){
                            Provider
                            Amount
                            Days
                            
                            poolAddress
                            blockTimestamp
                            transactionHash
                          } 
                        }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data;

                if (res.stakeds.length > 0) {
                    const mappedData = res.stakeds.map(item => ({
                        ...item,
                        user: item.Provider,
                        amount: item.Amount
                    }));
                    setData(mappedData);
                }
               
            }


        } catch (err) {
            console.error(err)
        }
    }, [address, setData])



    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 12000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data,
        }
    }, [data])
}

