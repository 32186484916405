import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useQueryAndSetAddress } from "./userAddress";

export const useGlobalData = () => {
    const [data, setData] = useState([])
    const [totalStaked, setTotalStaked] = useState([])
    const { address } = useQueryAndSetAddress()


    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {
                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{ 
                                rewardClaimeds(where: {claimer: "${address}"}) {
                                poolAddress
                                claimedAmount
                                blockTimestamp
                              }
                              stakeds(where: {Provider: "${address}"}) {
                                poolAddress
                                Provider
                                Amount
                              }
                            }`
                    }
                };
                const response = await axios.request(options);
                console.log(response?.data?.data)
                const res = response?.data?.data?.rewardClaimeds;
                const res2 = response?.data?.data?.stakeds.map(stake => ({
                    ...stake,
                    user: stake.Provider,
                    amount: stake.Amount
                }));
                if (res?.length > 0) {

                    setData(res)
                }
                if (res2.length > 0) {

                    setTotalStaked(res2)
                }
            }
            else {
                setData([])
                setTotalStaked([])
            }

        } catch (err) {
            console.error(err)
        }
    }, [address, setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 6000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data,
            totalStaked
        }
    }, [data, totalStaked])
}


