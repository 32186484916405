import React from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
const Faq6 = () => {
  return (
    <>
      <section className="faq">
        <div className="container">
          <div className="titlebox">
            <div className="h2div w-100 custom-max-w-fit mx-auto mb-2 px-4">
              <h3 className="">FAQ</h3>
            </div>
            <h2>F.A.Q.</h2>
            {/* <h2 >
                Boost Your Rewards Experience with Seamless, Secure Digital
                Asset Integration.
              </h2> */}
          </div>
          <CAccordion flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>
                Are there multiple pools for staking TMT?
              </CAccordionHeader>
              <CAccordionBody>
                - Yes, various pools are available for different staking
                durations.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>
                {" "}
                What's the penalty for unstaking before maturity?
              </CAccordionHeader>
              <CAccordionBody>
                - A 9% deduction will be applied to the staked amount.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={3}>
              <CAccordionHeader>
              Is there any fee when unstaking my tokens after maturity?
              </CAccordionHeader>
              <CAccordionBody>
              - Yes, there is an admin fee of 3% of the staked amount when you unstake your tokens after maturity.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={4}>
              <CAccordionHeader>
                How do I choose the best staking pool?
              </CAccordionHeader>
              <CAccordionBody>
                - Evaluate based on your investment goals and the pool's
                returns.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={5}>
              <CAccordionHeader>
                {" "}
                Are there referral bonuses in TMT Staking?
              </CAccordionHeader>
              <CAccordionBody>
                - Yes, there are three levels: 1st at 3%, 2nd at 2%, and 3rd at
                1%.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={6}>
              <CAccordionHeader>
                {" "}
                What tokens do I receive when I stake TMT?
              </CAccordionHeader>
              <CAccordionBody>
                - You will be rewarded with TMT tokens.
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </div>
      </section>
    </>
  );
};

export default Faq6;
